export type Enum<TEnum extends object> = TEnum[keyof TEnum];

export const TENANT_SUBSCRIPTIONL_STATUS = {
    ACTIVE: 'active',
    IN_TRIAL: 'in_trial',
    PAUSED: 'paused',
    CANCELLED: 'cancelled',
    
} as const;

export type SubscriptionPlan = Enum<typeof TENANT_SUBSCRIPTIONL_STATUS>;
